import { Link } from '../ui/Link'
import { SiteConfig } from '../providers/SiteConfig'
import { FeatMap } from './FeatMap'
import { SvgAtMdxPath } from './Svg'
import { Toc } from './Toc'
import { MdxProps } from '../providers/AllMdx'
import { Carousel as CarouselView } from '../../views/Carousel'
import { ContactUs } from '../ui/ContactUs/ContactUs'
import { ContactUs as ContactUs2 } from '../ui/ContactUs2/ContactUs'

export { MDXLayoutComponents, mdxLayoutStyles } from './mdxStyles'
export { Svg } from './Svg'
export const MDXGlobalComponents = (m: MdxProps) => ({
  Link,
  FeatMap,
  CarouselView,
  Svg: SvgAtMdxPath(m),
  Toc: Toc(m),
  Org: SiteConfig.Org,
  SiteUrl: SiteConfig.SiteUrl,
  Contact: ContactUs,
  Contact2: ContactUs2,
  OrgTitle: SiteConfig.Title,
  OrgDesc: SiteConfig.Description,
  OrgFavicon: SiteConfig.Favicon,
  OrgIcon: SiteConfig.Icon,
  OrgLogo: SiteConfig.Logo,
})
