// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-about-us-mdx": () => import("./../content/pages/about-us.mdx" /* webpackChunkName: "component---content-pages-about-us-mdx" */),
  "component---content-pages-contact-us-mdx": () => import("./../content/pages/contact-us.mdx" /* webpackChunkName: "component---content-pages-contact-us-mdx" */),
  "component---content-pages-terms-of-service-mdx": () => import("./../content/pages/terms-of-service.mdx" /* webpackChunkName: "component---content-pages-terms-of-service-mdx" */),
  "component---content-pages-terms-privacy-mdx": () => import("./../content/pages/terms-privacy.mdx" /* webpackChunkName: "component---content-pages-terms-privacy-mdx" */),
  "component---content-projects-amelie-nyc-mdx": () => import("./../content/projects/amelie-nyc.mdx" /* webpackChunkName: "component---content-projects-amelie-nyc-mdx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-amelie-nyc-tsx": () => import("./../src/pages/amelie-nyc.tsx" /* webpackChunkName: "component---src-pages-amelie-nyc-tsx" */),
  "component---src-pages-architecture-tsx": () => import("./../src/pages/architecture.tsx" /* webpackChunkName: "component---src-pages-architecture-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-furniture-tsx": () => import("./../src/pages/furniture.tsx" /* webpackChunkName: "component---src-pages-furniture-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interiors-tsx": () => import("./../src/pages/interiors.tsx" /* webpackChunkName: "component---src-pages-interiors-tsx" */)
}

