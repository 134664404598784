/* eslint-disable @typescript-eslint/camelcase */
// const ameliesf = {
//   date: '2012',
//   address: 'Amélie Wine Bar/Lounge, 1754 Polk Street, San Francisco',
//   city: 'San Francisco',
//   state: 'CA',
//   GPSLatitude: '37 47 36.2',
//   GPSLatitudeRef: 'N',
//   GPSLongitude: '122 25 26.0',
//   GPSLongitudeRef: 'W'
// }
const amelienyc = {
  date: '2012',
  address: 'Amélie Wine Bar, New York - West Village',
  city: 'New York City',
  state: 'NY',
  GPSLatitude: '40 43 57.9',
  GPSLatitudeRef: 'N',
  GPSLongitude: '73 59 51.7',
  GPSLongitudeRef: 'W'
}
const petiteamelienyc = {
  date: '2018',
  address: 'Petite Amélie Wine Bar, New York - Upper West side',
  city: 'New York City',
  state: 'NY',
  GPSLatitude: '40 47 20.2',
  GPSLatitudeRef: 'N',
  GPSLongitude: '73 58 28.3',
  GPSLongitudeRef: 'W'
}
const petiteamelienyc2 = {
  date: '2018',
  address: 'Lights Wall Art - Petite Amélie Wine Bar, New York - Upper West side',
  city: 'New York City',
  state: 'NY',
  GPSLatitude: '40 47 20.2',
  GPSLatitudeRef: 'N',
  GPSLongitude: '73 58 28.3',
  GPSLongitudeRef: 'W'
}
const chouquet = {
  date: '2012',
  address: "Chouquet's Restaurant, San Francisco",
  city: 'San Francisco',
  state: 'CA',
  GPSLatitude: '37 47 31.0',
  GPSLatitudeRef: 'N',
  GPSLongitude: '122 26 04.7',
  GPSLongitudeRef: 'W'
}
const nextworld = {
  date: '2012',
  address: 'Next World Capital, San Francisco',
  city: 'San Francisco',
  state: 'CA',
  GPSLatitude: '37 47 50.2',
  GPSLatitudeRef: 'N',
  GPSLongitude: '122 24 11.7',
  GPSLongitudeRef: 'W'
}
const bateau = {
  date: '2013',
  address: 'Bateau Hair Salon, San Francisco',
  city: 'San Francisco',
  state: 'CA',
  GPSLatitude: '37 46 57.5',
  GPSLatitudeRef: 'N',
  GPSLongitude: '122 23 18.9',
  GPSLongitudeRef: 'W'
}
// const garcon = {
//   date: '2005',
//   address: 'Garcon Restaurant, San Francisco',
//   city: 'San Francisco',
//   state: 'CA',
//   GPSLatitude: '37 45 19.4',
//   GPSLatitudeRef: 'N',
//   GPSLongitude: '122 25 14.7',
//   GPSLongitudeRef: 'W'
// }
const coleValley2 = {
  date: '2009',
  address: `9'x30' Wall Installation - Private Home, San Francisco`,
  city: 'San Francisco',
  state: 'CA',
  GPSLatitude: '37 45 56.2',
  GPSLatitudeRef: 'N',
  GPSLongitude: '122 27 05.1',
  GPSLongitudeRef: 'W'
}
const coleValley = {
  date: '2009',
  address: 'Private Home, San Francisco',
  city: 'San Francisco',
  state: 'CA',
  GPSLatitude: '37 45 56.2',
  GPSLatitudeRef: 'N',
  GPSLongitude: '122 27 05.1',
  GPSLongitudeRef: 'W'
}
// const coleValley2 = {
//   date: '2009',
//   address: 'Wall Installation - Private Home, San Francisco',
//   city: 'San Francisco',
//   state: 'CA',
//   GPSLatitude: '37 45 56.2',
//   GPSLatitudeRef: 'N',
//   GPSLongitude: '122 27 05.1',
//   GPSLongitudeRef: 'W'
// }
const sonora = {
  date: '2013',
  address: 'Private Home, Sonora',
  city: 'Sonora',
  state: 'CA',
  GPSLatitude: '37 57 16.9',
  GPSLatitudeRef: 'N',
  GPSLongitude: '120 16 26.4',
  GPSLongitudeRef: 'W'
}
const fogDesign = {
  date: '2013',
  address: 'Fog Design + Art 2014, San Francisco',
  city: 'Sonora',
  state: 'CA',
  GPSLatitude: '',
  GPSLatitudeRef: 'N',
  GPSLongitude: '',
  GPSLongitudeRef: 'W'
}

const images = {
  home: {
    _0002_lit: {
      title: 'BAR',
      location: amelienyc
    },
    _0002_lit2: {
      title: 'INTERIOR',
      location: amelienyc
    },
    _0007_Background: {
      title: 'RECEPTION DESK',
      location: nextworld
    },
    _0006_lit: {
      title: 'RECEPTION DESK',
      location: nextworld
    },
    _0000_lit: {
      title: '2xC2H5OH/CHANDELIER, SCONCES',
      location: amelienyc
    },
    '_0001_lit-copy-4': {
      title: 'INTERIOR',
      location: bateau
    },
    '_0001_lit-copy-5': {
      title: '2xC2H5OH/CHANDELIER, SCONCES',
      location: amelienyc
    },
    _0004_Background: {
      title: 'SERPENTINE/BOOK SHELF',
      location: coleValley
    },
    _0005_lit: {
      title: 'RECEPTION DESK',
      location: nextworld
    },
    _0004_lit: {
      title: 'RECEPTION DESK',
      location: bateau
    },
    _0003_lit: {
      title: 'RECEPTION DESK',
      location: nextworld
    },
    Wall: {
      title: `9'x30' Wall Installation`,
      location: coleValley2
    },
    Wall_orig: {
      title: `9'x30' Wall Installation`,
      location: coleValley2
    }
  },
  architecture: {
    '1767 North Point-Color Interior Elevation.svg': {
      title: 'Interior Remodel',
      location: coleValley
    },
    'DMG Nuz Staircase A2 1 1-2 Scale 4-19-2019.svg': {
      title: 'Staircase Study',
      location: coleValley
    },
    'Mark Houpin S2 1-2-14-Fog.svg': {
      title: 'Geodesic Dome House/Extension',
      location: sonora
    },
    'Marc Houpin S1 4-1-14-Fog.svg': {
      title: 'Geodesic Dome House/Extension',
      location: sonora
    }
  },
  interiors: {
    _0002_lit2: {
      title: 'INTERIOR/Restaurant',
      location: amelienyc
    },
    amelie: {
      title: 'INTERIOR/Restaurant/Elevation',
      location: amelienyc
    },
    _0000_lit: {
      title: 'INTERIOR/Restaurant/Detail',
      location: amelienyc
    },
    '_0001_lit-copy-5': {
      title: 'INTERIOR/Restaurant/Detail',
      location: amelienyc
    },
    _MG_6265: {
      title: 'INTERIOR/Restaurant',
      location: chouquet
    },
    _MG_6297: {
      title: 'INTERIOR/Restaurant/Detail',
      location: chouquet
    },
    _MG_6286: {
      title: 'INTERIOR/Restaurant',
      location: chouquet
    },
    _MG_6293: {
      title: 'INTERIOR/Restaurant',
      location: chouquet
    },
    _MG_6308: {
      title: 'INTERIOR/Restaurant',
      location: chouquet
    },
    _MG_6276: {
      title: 'INTERIOR/Restaurant',
      location: chouquet
    },
    _MG_6270: {
      title: 'INTERIOR/Restaurant',
      location: chouquet
    },
    // _MG_6373: {
    //   title: 'INTERIOR/Bistrot',
    //   location: garcon
    // },
    // _MG_6317: {
    //   title: 'INTERIOR/Bistrot',
    //   location: garcon
    // },
    // _MG_6381: {
    //   title: 'INTERIOR/Bistrot',
    //   location: garcon
    // },
    // _MG_6327: {
    //   title: 'INTERIOR/Bistrot/Detail',
    //   location: garcon
    // },
    // _MG_6355: {
    //   title: 'INTERIOR/Bistrot',
    //   location: garcon
    // },
    '_0001_lit-copy-4': {
      title: 'INTERIOR/Hair Salon',
      location: bateau
    },
    _0004_lit: {
      title: 'INTERIOR/Hair Salon',
      location: bateau
    },
    Wall: {
      title: 'Bottles LED lights Wall',
      location: petiteamelienyc
    },
    WallPanorama: {
      title: 'Lights Wall Art - Petite Amélie Wine Bar',
      location: petiteamelienyc2,
      exclude: true // exclude from interior view
    },
    _PP_5269: {
      title: 'Low Partition Divider',
      location: petiteamelienyc
    },
    _PP_5280: {
      title: 'Bottle LED Light',
      location: petiteamelienyc
    },
    _PP_5279: {
      title: 'Decor Details',
      location: petiteamelienyc
    },
    _PP_5309: {
      title: 'Foldable Table Wall',
      location: petiteamelienyc
    },
    _PP_5298: {
      title: 'Materials Details',
      location: petiteamelienyc
    },
    _PP_5261: {
      title: 'Dining',
      location: petiteamelienyc
    },
    _PP_5293: {
      title: 'Wall Materials Details',
      location: petiteamelienyc
    },
    _PP_5296: {
      title: 'Wall Materials Details',
      location: petiteamelienyc
    },
    _PP_5323: {
      title: 'Foldable Table Wall',
      location: petiteamelienyc
    },
    _PP_5180: {
      title: 'Bar Shelves Details',
      location: petiteamelienyc
    },
    _PP_5250: {
      title: 'Foldable Table Details',
      location: petiteamelienyc
    },
    _PP_5232: {
      title: 'Main Dining',
      location: petiteamelienyc
    },
    _PP_5184: {
      title: 'Designer Maxime Genauzeau',
      location: petiteamelienyc
    },
    _PP_5191: {
      title: 'Bar',
      location: petiteamelienyc
    },
    _PP_5228: {
      title: 'Folded Wall Table',
      location: petiteamelienyc
    },
    _PP_5209: {
      title: 'Bar Seat',
      location: petiteamelienyc
    },
    _PP_5192: {
      title: 'Bar',
      location: petiteamelienyc
    },
    _PP_5241: {
      title: 'Bench with Mirror',
      location: petiteamelienyc
    },
    _PP_5205: {
      title: 'Bench and Bar',
      location: petiteamelienyc
    },
    _PP_5197: {
      title: 'Bar Top meets Seat',
      location: petiteamelienyc
    },
    _PP_5178: {
      title: 'Bar',
      location: petiteamelienyc
    },
    _PP_5301: {
      title: 'Wall Materials Details',
      location: petiteamelienyc
    }
  },
  furniture: {
    _0000_lit: {
      title: 'C2H5OH/Sconces',
      location: amelienyc
    },
    '_0001_lit-copy-5': {
      title: 'C2H5OH/2xPendant',
      location: amelienyc
    },
    _0005_lit: {
      title: 'RECEPTION DESK',
      location: nextworld
    },
    _0003_lit: {
      title: 'RECEPTION DESK',
      location: nextworld
    },
    'Serpentine 2': {
      title: 'SERPENTINE/Book Case',
      location: fogDesign
    },
    _0004_Background: {
      title: 'SERPENTINE/Book Case',
      location: coleValley
    },
    _0004_lit: {
      title: 'RECEPTION DESK',
      location: bateau
    }
  }
}
function getOrderedListByCat() {
  const orderedImages = {}
  Object.keys(images).forEach(cat => {
    Object.keys(images[cat]).forEach(img => {
      if (!(cat in orderedImages)) {
        orderedImages[cat] = []
      }
      const imgDict = images[cat][img]
      if (typeof imgDict.exclude === `undefined` || imgDict.exclude === false) {
        let ext = ''
        if (img.search('.svg') === -1) {
          ext = '.jpg'
        }
        orderedImages[cat].push(`${img}${ext}`)
      }
    })
  })
  return orderedImages
}

module.exports = {
  imageInfoDict: images,
  orderedImages: getOrderedListByCat()
}
